import { SitePage } from 'app/layout';
import { Appendix } from 'app/appendix';

export default function AppendixPage() {
  return (
    <SitePage>
      <Appendix />
    </SitePage>
  );
}
