import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { ReactNode } from 'react';

export type ContentAppendixProps = {
  title: string;
  references?: ReactNode[];
  summary?: ReactNode;
  firstReferenceNumber: number;
};

export function ContentAppendix(props: ContentAppendixProps) {
  const { title, references, firstReferenceNumber, summary } = props;

  const theme = useTheme();

  return (
    <>
      <Typography variant="h6" sx={{ marginTop: theme.spacing(1) }}>
        {title}
      </Typography>
      {summary}
      {references && (
        <>
          <Typography sx={{ marginTop: theme.spacing(1) }}>References:</Typography>
          <ol start={firstReferenceNumber}>
            {references?.map((reference, index) => (
              <ListItem key={index}>{reference}</ListItem>
            ))}
          </ol>
        </>
      )}
    </>
  );
}

const ListItem = styled('li')(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  // CSS for putting margin between adjacent 'sibling' li elements
  '& + &': { marginTop: theme.spacing(1) },
}));
