import { Typography } from '@mui/material';
import { ExtLinkNoUnderline } from './ExtLinkNoUnderline';

export const CredibleResources = (props: { subheading: boolean }) => {
  return (
    <div>
      <Typography component="span" paragraph variant={props.subheading ? 'h5' : 'body1'}>
        Credible sources about COVID-19
      </Typography>

      <Typography component="span" paragraph>
        <ul>
          <li>
            <ExtLinkNoUnderline href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019">
              World Health Organization
            </ExtLinkNoUnderline>
          </li>
          <li>
            <ExtLinkNoUnderline href="https://www.australia.gov.au/">
              Australian government coronavirus (COVID-19) information
            </ExtLinkNoUnderline>
          </li>
          <li>
            Government information for each state:
            <ul>
              <li>
                <ExtLinkNoUnderline href="https://www.coronavirus.vic.gov.au/">Victoria </ExtLinkNoUnderline>
              </li>
              <li>
                <ExtLinkNoUnderline href="https://www.nsw.gov.au/covid-19">New South Wales</ExtLinkNoUnderline>
              </li>
              <li>
                <ExtLinkNoUnderline href="https://www.qld.gov.au/health/conditions/health-alerts/coronavirus-covid-19/current-status/urgent-covid-19-update">
                  Queensland
                </ExtLinkNoUnderline>
              </li>
              <li>
                <ExtLinkNoUnderline href="https://www.covid19.act.gov.au/">
                  Australian Capital Territory
                </ExtLinkNoUnderline>
              </li>
              <li>
                <ExtLinkNoUnderline href="https://www.coronavirus.tas.gov.au/">Tasmania</ExtLinkNoUnderline>
              </li>
              <li>
                <ExtLinkNoUnderline href="https://www.wa.gov.au/organisation/department-of-the-premier-and-cabinet/covid-19-coronavirus-latest-updates">
                  Western Australia
                </ExtLinkNoUnderline>
              </li>
              <li>
                <ExtLinkNoUnderline href="https://www.covid-19.sa.gov.au/">South Australia</ExtLinkNoUnderline>
              </li>
              <li>
                <ExtLinkNoUnderline href="https://coronavirus.nt.gov.au/">Northern Territory</ExtLinkNoUnderline>
              </li>
            </ul>
          </li>
        </ul>
      </Typography>
    </div>
  );
};
